import api from '@/libs/axios'

export const createAdvertiser = (data) => api.post('/api/advertiser', data)

export const activateAdvertiser = (id) => api.post(`/api/user/${id}/activate`)

export const deactivateAdvertiser = (id) => api.post(`/api/user/${id}/deactivate`)

export const updateAdvertiser = (data) => api.patch(`/api/advertiser/${data.id}`, data)

export const fetchAdvertisers = (query) => api.get(`/api/advertiser${query ? `?${query}` : ''}`)
